@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

body {
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.4em;
  line-height: 1.2;
}

header.section-title {
  margin-bottom: 2rem;
}
header.section-title h1 {
  margin-bottom: 0.5em;
}
header.section-title p {
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 0.5em;
}
header.section-title p + p {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.875rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a, .read-more-btn {
  color: rgb(70, 170, 255);
  transition: all 0.3s ease;
}
a:hover, .read-more-btn:hover {
  transition: all 0.1s ease-out;
  color: rgb(110, 230, 255);
  cursor: pointer;
}

.btn {
  background-color: #1a1a1a;
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 2em;
  width: 100%;
  text-align: center;
  border-radius: 3px;
  font-weight: 500;
  margin-top: 3rem;
  font-size: 0.875rem;
}
.btn.external-link::after {
  content: url("../assets/icons/external-link-icon-white.svg");
  width: 1.125em;
  display: inline-block;
  position: relative;
  top: 0.1em;
  right: -0.5em;
}
.btn:hover {
  color: #ffffff;
  background-color: rgb(80, 80, 80);
}

.read-more-link, .read-more-btn {
  display: inline-block;
  width: max-content;
  padding: 0.25rem 0;
}

.read-more-btn {
  background: transparent;
  border: none;
  text-decoration: underline;
}

.content-wrap {
  padding: 6rem 1rem;
}

@keyframes still-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (min-width: 601px) {
  .content-wrap {
    padding: 8rem 2rem;
  }

  .btn {
    text-transform: uppercase;
    padding: 0.5em 3em;
    width: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .content-wrap {
    padding: 8rem 4rem;
  }

  p {
    font-size: 1.125rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  header.section-title {
    margin-bottom: 4rem;
  }
  header.section-title p {
    font-size: 1.4rem;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  header.section-title p + p {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1900px) {
  h1 {
    font-size: 3rem;
  }

  .content-wrap {
    padding: 10rem 6rem;
  }

  .btn {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 2400px) {
  .content-wrap {
    padding: 10rem;
  }

  h1 {
    font-size: 3.5rem;
  }

  header.section-title {
    margin-bottom: 5rem;
  }
  header.section-title p {
    font-size: 1.6rem;
    line-height: 1;
    color: rgba(0, 0, 0, 0.4);
  }
}
.coloured-icon,
.social-icon {
  display: inline-block;
  mask-size: cover !important;
}

.mask:hover {
  cursor: pointer;
}
.mask.handset {
  mask: url("../assets/icons/icon-handset.svg");
}
.mask.envelope {
  mask: url("../assets/icons/icon-envelope.svg");
}
.mask.linkedin {
  mask: url("../assets/icons/icon-linkedin.svg");
}
.mask.github {
  mask: url("../assets/icons/icon-github.svg");
}
.mask.behance {
  mask: url("../assets/icons/icon-behance.svg");
}
.mask.facebook {
  mask: url("../assets/icons/icon-facebook.svg");
}

.back-arrow-wrap {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0 0 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.back-arrow-wrap.fade-in {
  opacity: 1;
}
.back-arrow-wrap.fade-in.contact {
  transition: opacity 0.5s ease 2s;
}
.back-arrow-wrap.fade-in.about {
  transition: opacity 0.5s ease 0.9s;
}
.back-arrow-wrap .back-arrow {
  mask: url("../assets/icons/back-arrow.svg");
  width: 20px;
  height: 30px;
}
.back-arrow-wrap .back-arrow:hover {
  cursor: pointer;
}

.scroll-btn-wrap {
  width: 3.3rem;
  height: 3.3rem;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  bottom: 0;
  right: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease 0.2s;
  z-index: 2;
}
.scroll-btn-wrap.reveal {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s ease;
}
.scroll-btn-wrap .btn-icon {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -3rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  margin: 0;
  transition: right 0.2s ease;
}
.scroll-btn-wrap .btn-icon.reveal {
  right: 0;
  transition: right 0.3s ease;
}
.scroll-btn-wrap .btn-icon img {
  width: 70%;
  height: 70%;
}
.scroll-btn-wrap .scroll-btn {
  border-radius: 0;
  border: none;
  background: transparent;
  width: 3rem;
  height: 3rem;
  position: fixed;
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.1), transparent 15%);
  position: fixed;
  bottom: 0;
  right: 0;
  transition: all 0.1s ease;
  opacity: 0;
  cursor: pointer;
}
.scroll-btn-wrap .scroll-btn.reveal {
  opacity: 1;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 1024px) {
  .scroll-btn-wrap {
    display: block;
    width: 8vh;
    height: 8vh;
  }
  .scroll-btn-wrap .btn-icon {
    right: 8vh;
    width: 100%;
    height: 100%;
  }
  .scroll-btn-wrap .btn-icon.reveal {
    right: 0;
  }
  .scroll-btn-wrap .btn-icon img {
    width: 66%;
    height: 66%;
  }
  .scroll-btn-wrap .scroll-btn {
    width: 8vh;
    height: 8vh;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), transparent 15%);
    cursor: pointer;
  }

  .back-arrow-wrap {
    width: 4rem;
    height: 5rem;
    box-shadow: none;
  }
  .back-arrow-wrap .back-arrow {
    width: 23px;
    height: 34px;
  }
}
@media only screen and (min-width: 1920px) {
  .scroll-btn-wrap {
    display: block;
    width: 8vh;
    height: 8vh;
  }
  .scroll-btn-wrap .btn-icon {
    right: 8vh;
    width: 100%;
    height: 100%;
  }
  .scroll-btn-wrap .btn-icon.reveal {
    right: 0;
  }
  .scroll-btn-wrap .btn-icon img {
    width: 66%;
    height: 66%;
  }
  .scroll-btn-wrap .scroll-btn {
    width: 8vh;
    height: 8vh;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1), transparent 15%);
    cursor: pointer;
  }

  .back-arrow-wrap {
    width: 4rem;
    height: 5rem;
    box-shadow: none;
  }
  .back-arrow-wrap .back-arrow {
    width: 23px;
    height: 34px;
  }
}
@media only screen and (min-width: 2400px) {
  .back-arrow-wrap {
    width: 5rem;
    height: 5rem;
  }
  .back-arrow-wrap .back-arrow {
    width: 27px;
    height: 40px;
  }
}
.popup-container {
  display: none;
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  .popup-container {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 20, 0.6);
    justify-content: center;
    align-items: center;
  }
  .popup-container .popup-wrap {
    padding: 3em 4em;
    background-color: #ffffff;
    border: solid 2px #000000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .popup-container .popup-wrap h2 {
    margin-bottom: 0.4em;
  }
  .popup-container .popup-wrap p {
    margin-bottom: 0.3em;
  }
  .popup-container .popup-wrap span {
    font-weight: 600;
  }
}
@media only screen and (max-width: 600px) {
  .popup-container .popup-wrap {
    padding: 1em;
    width: 90%;
    text-align: center;
  }
}
/* --------------- Background --------------- */
.coloured-bg {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(140deg, rgba(170, 150, 220, 0.4), #a5beff, #ffa66b, #ffc071, rgba(255, 245, 180, 0.6), rgba(255, 210, 120, 0.5), rgba(170, 150, 220, 0.4), #a5beff, #ffa66b, #ffc071, rgba(255, 245, 180, 0.6), rgba(170, 150, 220, 0.4), #a5beff, #ffa66b);
  background-size: 1200% 1200%;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
.lines {
  width: 100%;
  height: 100vh;
  top: 0;
  background-image: url("../assets/images/diagonal-lines-BG-white-50-2px-03.svg");
  position: fixed;
  background-size: 1920px;
  background-repeat: repeat;
}

.white-bg-wrap {
  align-self: stretch;
  width: 100%;
  padding-top: 6vh;
  padding-bottom: 6vh;
  padding-left: 0vh;
  padding-right: 0vh;
  transition: padding-top 0.2s ease-out 0.2s, padding-bottom 0.2s ease-out 0.2s, padding-left 0.2s ease-in, padding-right 0.2s ease-in, background-color 1s ease;
}
.white-bg-wrap.resize-white-bg {
  padding-top: 0vh;
  padding-bottom: 0vh;
  transition: padding-top 0.2s ease-in, padding-bottom 0.2s ease-in;
}
.white-bg-wrap .white-bg {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.height-initial {
  height: initial !important;
}

.content-wrap {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .coloured-bg .white-bg-wrap {
    align-self: stretch;
    width: 100%;
    padding-top: 6vh;
    padding-bottom: 6vh;
    padding-left: 0vh;
    padding-right: 0vh;
    transition: padding-top 0.2s ease-out 0.2s, padding-bottom 0.2s ease-out 0.2s, padding-left 0.2s ease-in, padding-right 0.2s ease-in, background-color 1s ease;
  }
  .coloured-bg .white-bg-wrap.resize-white-bg {
    padding-top: 0vh;
    padding-bottom: 0vh;
    padding-left: 3vh;
    padding-right: 3vh;
    transition: padding-top 0.2s ease-in, padding-bottom 0.2s ease-in, padding-left 0.2s ease-out 0.2s, padding-right 0.2s ease-out 0.2s;
  }
  .coloured-bg .lines {
    background-size: initial;
  }
}
@media only screen and (min-width: 1440px) {
  .coloured-bg .white-bg-wrap.resize-white-bg {
    padding-left: 8vh;
    padding-right: 8vh;
  }
}
@media only screen and (min-width: 1601px) {
  .coloured-bg .white-bg-wrap.resize-white-bg {
    padding-left: 8vh;
    padding-right: 8vh;
  }
  .coloured-bg .lines {
    background-size: initial;
  }
}
header.main-nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  z-index: 3;
  opacity: 0;
}
header.main-nav.nav-fade-in {
  transition: all 0.5s ease;
  opacity: 1;
}
header.main-nav nav {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #1a1a1a;
  transition: all 1.6s ease;
}
header.main-nav nav.active {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease;
}
header.main-nav nav ul li {
  font-size: 2rem;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -100vw;
  transition: all 0 0.8s;
}
header.main-nav nav ul li.slide-in-01 {
  margin-left: 0;
  transition: all 0.3s 0.02s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header.main-nav nav ul li.slide-in-02 {
  margin-left: 0;
  transition: all 0.3s 0.08s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header.main-nav nav ul li.slide-in-03 {
  margin-left: 0;
  transition: all 0.3s 0.14s cubic-bezier(0.075, 0.82, 0.165, 1);
}
header.main-nav nav ul li span {
  padding: 1rem;
  color: #ffffff;
  position: relative;
  left: 0;
  opacity: 1;
}
header.main-nav nav ul li span.link-clicked {
  left: 100%;
  opacity: 0;
  transition: left 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.2s ease-out;
}
header.main-nav .burger {
  display: block;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 2;
}
header.main-nav .burger .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
  background-color: #1a1a1a;
}
header.main-nav .burger.active .bar {
  background-color: #ffffff;
}
header.main-nav .burger.active .bar:nth-child(2) {
  opacity: 0;
}
header.main-nav .burger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
header.main-nav .burger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

@media only screen and (min-width: 1024px) {
  header.main-nav nav {
    visibility: visible;
    opacity: 1;
    background: transparent;
  }
  header.main-nav nav ul {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    overflow: hidden;
  }
  header.main-nav nav ul li {
    width: 100%;
    height: 100%;
    margin: 0;
    /* About */
    /* Work */
    /* Contact */
  }
  header.main-nav nav ul li span {
    color: #1a1a1a;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0;
    transition: all 0.1s ease;
    display: inline-block;
    width: 10rem;
    height: 6rem;
  }
  header.main-nav nav ul li span:hover {
    letter-spacing: 1px;
    font-size: 2.5rem;
    width: 15rem;
    transition: all 0.3s ease;
    height: 7rem;
    cursor: pointer;
  }
  header.main-nav nav ul li span.link-clicked {
    left: 0;
  }
  header.main-nav nav ul li.item-01 {
    display: flex;
    align-items: center;
  }
  header.main-nav nav ul li.item-01 span {
    display: inline-block;
    transform: rotate(90deg);
    margin-left: -4rem;
  }
  header.main-nav nav ul li.item-02 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  header.main-nav nav ul li.item-02 span {
    margin-bottom: -2rem;
  }
  header.main-nav nav ul li.item-02 span:hover {
    margin-bottom: 0;
  }
  header.main-nav nav ul li.item-03 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  header.main-nav nav ul li.item-03 span {
    transform: rotate(270deg);
    margin-right: -4rem;
  }
  header.main-nav .burger {
    display: none;
  }
}
@media only screen and (min-width: 2400px) {
  header.main-nav nav ul li span {
    font-size: 2.25rem;
    width: 13rem;
    height: 6rem;
  }
  header.main-nav nav ul li span:hover {
    font-size: 3rem;
    width: 17rem;
    height: 9rem;
  }
  header.main-nav nav ul li.item-01 span {
    margin-left: -4rem;
  }
  header.main-nav nav ul li.item-02 span {
    margin-bottom: -0.5rem;
  }
  header.main-nav nav ul li.item-02 span:hover {
    margin-bottom: 0;
  }
  header.main-nav nav ul li.item-03 span {
    margin-right: -4rem;
  }
}
/* --------------- Homepage --------------- */
.content-wrap.home {
  position: relative;
  transition: all 0.2s ease;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  opacity: 1;
}
.content-wrap.home.content-up {
  opacity: 0;
  transition: all 0.4s ease-out;
}
.content-wrap.home.content-right {
  opacity: 0;
  transition: all 0.4s ease-out;
}
.content-wrap.home.content-left {
  opacity: 0;
  transition: all 0.4s ease-out;
}
.content-wrap.home.content-down {
  opacity: 0;
  transition: all 0.4s ease-out;
}
@keyframes shark {
  0% {
    -webkit-background-image: url("../assets/images/lil-shark.svg");
    background-image: url("../assets/images/lil-shark.svg");
  }
  59.9% {
    -webkit-background-image: url("../assets/images/lil-shark.svg");
    background-image: url("../assets/images/lil-shark.svg");
  }
  60% {
    -webkit-background-image: url("../assets/images/lil-shark-mouth-open.svg");
    background-image: url("../assets/images/lil-shark-mouth-open.svg");
  }
  79.9% {
    -webkit-background-image: url("../assets/images/lil-shark-mouth-open.svg");
    background-image: url("../assets/images/lil-shark-mouth-open.svg");
  }
  80% {
    -webkit-background-image: url("../assets/images/lil-shark.svg");
    background-image: url("../assets/images/lil-shark.svg");
  }
  100% {
    -webkit-background-image: url("../assets/images/lil-shark.svg");
    background-image: url("../assets/images/lil-shark.svg");
  }
}
.content-wrap.home .lil-shark {
  width: 130px;
  height: 82px;
  background-image: url("../assets/images/lil-shark.svg");
  position: absolute;
  background-position: bottom;
  background-repeat: no-repeat;
  top: -95px;
  opacity: 0;
  -webkit-animation-name: shark;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 1.3s;
  animation-name: shark;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1.3s;
}
.content-wrap.home .lil-shark.shark-fade-in {
  top: calc(-82px - 1.5rem);
  opacity: 1;
  transition: all 1s ease-out 0.4s;
}
.content-wrap.home .container.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-wrap.home .container.home .title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 250px;
}
.content-wrap.home .container.home .title-wrap .h1-wrap {
  overflow: hidden;
}
.content-wrap.home .container.home .title-wrap h1 {
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  top: 2em;
}
.content-wrap.home .container.home .title-wrap h1.h1-fade-in {
  top: 0;
  transition: all 0.4s 0.02s cubic-bezier(0, 1.3, 0.5, 1.075);
}
.content-wrap.home .container.home .title-wrap h2 {
  font-weight: 500;
  font-size: 1.125rem;
  text-align: center;
  width: 80%;
  line-height: 1.4;
  position: absolute;
  opacity: 1;
  bottom: -3.25rem;
  transition: all 1s ease-out 0.4s;
}
.content-wrap.home .container.home .title-wrap h2.h2-fade-in {
  bottom: -2.5rem;
  opacity: 0;
}
.content-wrap.home .container.home .social-icons {
  position: absolute;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  opacity: 0;
  z-index: 3;
}
.content-wrap.home .container.home .social-icons.social-fade-in {
  transition: all 0.5s ease;
  opacity: 1;
}
.content-wrap.home .container.home .social-icons li {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-wrap.home .container.home .social-icons li a {
  width: 80%;
  height: 80%;
  display: inline-block;
  transition: all 0.4s ease-out;
}
.content-wrap.home .container.home .social-icons li a .social-icon {
  background-color: #1a1a1a;
  transition: all 0.4s ease-out;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 601px) {
  .content-wrap.home .container.home .title-wrap {
    width: 100%;
  }
  .content-wrap.home .container.home .title-wrap h1 {
    font-size: 2.75rem;
  }
  .content-wrap.home .container.home .title-wrap h2 {
    font-weight: 400;
    font-size: 1.25rem;
    bottom: -1.75rem;
    opacity: 1;
  }
  .content-wrap.home .container.home .title-wrap h2.h2-fade-in {
    bottom: -1em;
    opacity: 0;
    transition: all 1s ease-out 0.4s;
  }
  .content-wrap.home .container.home .social-icons {
    width: 140px;
  }
  .content-wrap.home .container.home .social-icons li {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-wrap.home .lil-shark {
    width: 170px;
    height: 110px;
    background-image: url("../assets/images/lil-shark.svg");
    position: absolute;
    background-position: bottom;
    background-repeat: no-repeat;
    top: -110px;
    opacity: 0;
    -webkit-animation-name: shark;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-delay: 1.3s;
    animation-name: shark;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 1.3s;
  }
  .content-wrap.home .lil-shark.shark-fade-in {
    top: calc(-110px - 1.5rem);
    opacity: 1;
    transition: all 1s ease-out 0.4s;
  }
}
@media only screen and (min-width: 1024px) {
  .white-bg.home {
    overflow: hidden;
  }
  .white-bg.home .content-wrap.home {
    position: relative;
    transition: all 0.1s ease;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    opacity: 1;
  }
  .white-bg.home .content-wrap.home.content-up {
    margin-top: -2rem;
    opacity: 0;
    transition: all 0.1s ease-out;
  }
  .white-bg.home .content-wrap.home.content-right {
    margin-left: 2rem;
    opacity: 0;
    transition: all 0.1s ease-out;
  }
  .white-bg.home .content-wrap.home.content-left {
    margin-left: -2rem;
    opacity: 0;
    transition: all 0.1s ease-out;
  }
  .white-bg.home .content-wrap.home.content-down {
    margin-top: 2rem;
    opacity: 0;
    transition: all 0.2s ease-out;
  }
  .white-bg.home .content-wrap.home .lil-shark {
    width: 175px;
    height: 110px;
    top: -110px;
  }
  .white-bg.home .content-wrap.home .lil-shark:hover {
    background-image: url("../assets/images/lil-shark-mouth-open.svg") !important;
  }
  .white-bg.home .content-wrap.home .lil-shark.shark-fade-in {
    top: -120px;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h1 {
    font-size: 3.5rem;
    line-height: 1.3;
    top: 1.5em;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h1.h1-fade-in {
    top: 0;
    transition: all 0.4s 0.02s cubic-bezier(0, 1.6, 0.9, 1.075);
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2 {
    font-weight: 300;
    font-size: 1.5rem;
    bottom: -0.75em;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2.h2-fade-in {
    bottom: -0.5em;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons {
    width: 145px;
    bottom: initial;
    top: 1rem;
    z-index: 4;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li {
    width: 35px;
    height: 35px;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li a {
    width: 80%;
    height: 80%;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li a:hover {
    width: 100%;
    height: 100%;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li a .social-icon.linkedin:hover {
    background-color: #0077b7;
    transition: all 0.2s ease;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li a .social-icon.behance:hover {
    background-color: #0057ff;
    transition: all 0.2s ease;
  }
}
@media only screen and (min-width: 1920px) {
  .white-bg.home .content-wrap.home .container.home .title-wrap h1 {
    font-size: 3.75rem;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2 {
    font-weight: 400;
    font-size: 1.5rem;
    bottom: -1.25rem;
    opacity: 1;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2.h2-fade-in {
    bottom: -0.5em;
    opacity: 0;
    transition: all 1s ease-out 0.4s;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap .lil-shark {
    width: 195px;
    height: 115px;
    top: -125px;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap .lil-shark:hover {
    background-image: url("../assets/images/lil-shark-mouth-open.svg") !important;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap .lil-shark.shark-fade-in {
    top: -135px;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons {
    width: 160px;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 2400px) {
  .white-bg.home .content-wrap.home .container.home .lil-shark {
    width: 240px;
    height: 150px;
    top: -160px;
  }
  .white-bg.home .content-wrap.home .container.home .lil-shark.shark-fade-in {
    top: -170px;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h1 {
    font-size: 5rem;
    line-height: 1.3;
    top: 1.5em;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2 {
    font-size: 2rem;
    bottom: -1em;
  }
  .white-bg.home .content-wrap.home .container.home .title-wrap h2.h2-fade-in {
    bottom: -0.5em;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons {
    width: 185px;
    top: 1.5rem;
  }
  .white-bg.home .content-wrap.home .container.home .social-icons li {
    width: 45px;
    height: 45px;
  }
}
/* --------------- About --------------- */
@keyframes expand-right {
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes shift-up {
  0% {
    top: 15px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.content-wrap.about {
  /* RESUME BUTTON */
  /* CAT ILLUSTRATION */
}
.content-wrap.about .about-grid {
  display: flex;
  flex-direction: column;
  /* PORTRAIT */
  /* ABOUT */
}
.content-wrap.about .about-grid h1, .content-wrap.about .about-grid h2 {
  font-size: 1.75rem;
  margin-bottom: 0.75rem;
  line-height: 1;
  text-transform: capitalize;
}
.content-wrap.about .about-grid .title-wrap .rule {
  margin-bottom: 1rem;
  width: 0;
  height: 2px;
  background-color: #1a1a1a;
  opacity: 0;
  animation-name: expand-right;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
.content-wrap.about .about-grid .grid-item-1 {
  margin-bottom: 2rem;
}
.content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
  width: 200px;
  height: 200px;
}
.content-wrap.about .about-grid .grid-item-1 .me-img-wrap img {
  opacity: 0;
  position: relative;
  animation-name: shift-up;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
}
.content-wrap.about .about-grid .grid-item-2 {
  margin-top: 1rem;
}
.content-wrap.about .about-grid .grid-item-3 {
  margin-top: 2rem;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .icon-wrap {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .icon-wrap .about-icons {
  width: 50%;
  opacity: 0;
  transition: width 0.6s ease, opacity 0.9s ease;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .icon-wrap .icons-revealed {
  width: 100%;
  opacity: 1;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap {
  width: 100%;
  padding-left: 0.75rem;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap h5 {
  color: #4d8c9f;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.5em;
  margin: 0 auto 0.5em 0;
  text-transform: uppercase;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar {
  width: 100%;
  height: 13px;
  background: #4d8c9f;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar .stats-bar-inner {
  background: #b2f3f3;
  width: 85%;
  height: 15px;
  border-radius: 5px;
  position: absolute;
  left: -90%;
  opacity: 0.5;
  transition: all 0.3s ease-out;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar .bars-slide-in {
  left: 0;
  opacity: 1;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .html .stats-bar-inner,
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .css .stats-bar-inner {
  width: 75%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .react .stats-bar-inner {
  width: 55%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .wordpress .stats-bar-inner {
  width: 80%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .seo .stats-bar-inner,
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .drawing .stats-bar-inner {
  width: 65%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .photoshop .stats-bar-inner,
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .illustrator .stats-bar-inner,
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .indesign .stats-bar-inner {
  width: 85%;
}
.content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-border {
  width: 100%;
  height: 100%;
  border: solid 2px #4d8c9f;
  border-radius: 5px;
  position: absolute;
  top: 0;
}
.content-wrap.about .resume-btn-wrap {
  width: 280px;
  min-height: 280px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(6deg);
  background-image: url("../assets/images/explosion-01.svg");
}
@keyframes explosion {
  0% {
    background-image: url("../assets/images/explosion-02.svg");
  }
  40% {
    background-image: url("../assets/images/explosion-01.svg");
  }
  100% {
    background-image: url("../assets/images/explosion-01.svg");
  }
}
.content-wrap.about .resume-btn-wrap.animate-explosion {
  animation-name: explosion;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
}
.content-wrap.about .resume-btn-wrap a {
  text-decoration: none;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border {
  border: solid 2px #1a1a1a;
  padding: 4px;
  border-radius: 9px;
  position: relative;
  color: #1a1a1a;
  background: #ffffff;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn {
  border: solid 2px #1a1a1a;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 1s ease;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn.resume-btn-hover {
  border: solid 2px #ffffff;
  transition: all 0.2s ease;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn img {
  max-width: 25px;
  margin-right: 8px;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn span {
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #1a1a1a;
  transition: all 0.4s ease;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn span.resume-btn-span-hover {
  color: #ffffff !important;
  transition: all 0.8s ease;
}
@keyframes move-arrow-left {
  0%, 100% {
    left: -2.75em;
  }
  50% {
    left: -3.75em;
  }
}
@keyframes move-arrow-right {
  0%, 100% {
    right: -2.75em;
  }
  50% {
    right: -3.75em;
  }
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left,
.content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
  width: 2em;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left {
  position: absolute;
  margin-right: 2rem;
  animation-name: move-arrow-left;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  left: -80px;
  top: 0.5em;
}
.content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
  transform: rotate(180deg);
  position: absolute;
  animation-name: move-arrow-right;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  right: -80px;
  top: 0.5em;
}
.content-wrap.about .cat-img-wrap {
  display: none;
}

@media only screen and (min-width: 601px) {
  .content-wrap.about .about-grid {
    max-width: 480px;
  }
  .content-wrap.about .about-grid h1, .content-wrap.about .about-grid h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .content-wrap.about {
    margin-left: 0;
    opacity: 1;
    transition: all 0.1s ease;
  }
  .content-wrap.about.content-left {
    margin-left: -2rem;
    opacity: 0;
    transition: all 0.1s ease-out;
  }
  .content-wrap.about .about-grid {
    max-width: 600px;
    margin: auto;
  }
  .content-wrap.about .about-grid h1, .content-wrap.about .about-grid h2 {
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
  }
  .content-wrap.about .about-grid .title-wrap .rule {
    margin-bottom: 1.25rem;
  }
  .content-wrap.about .about-grid .grid-item-2 {
    margin-top: 1.5rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap {
    padding-right: 2rem;
    margin-bottom: 1.25rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .icon-wrap {
    width: 45px;
    height: 45px;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap h5 {
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1601px) {
  .content-wrap.about {
    padding: 4rem 0 0 4rem;
    /* CAT ILLUSTRATION */
    /* RESUME BUTTON */
  }
  .content-wrap.about .cat-img-wrap {
    display: block;
    position: absolute;
    right: 0;
    top: 20%;
    width: 23vw;
  }
  .content-wrap.about .about-grid {
    display: grid;
    grid-auto-columns: max-content;
    column-gap: 3rem;
    max-width: 1400px;
    margin: auto auto auto 0;
    padding-bottom: 5rem;
    /* PORTRAIT */
    /* ABOUT */
  }
  .content-wrap.about .about-grid h1, .content-wrap.about .about-grid h2 {
    font-size: 2.5rem;
    line-height: 1em;
    margin-bottom: 2rem;
  }
  .content-wrap.about .about-grid .title-wrap {
    position: relative;
  }
  .content-wrap.about .about-grid .title-wrap .rule {
    margin-bottom: 0;
    position: absolute;
    bottom: -1rem;
  }
  .content-wrap.about .about-grid .grid-item-1 {
    grid-column: 1/2;
    padding-top: 1rem;
  }
  .content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
    width: 200px;
    height: 200px;
    position: relative;
  }
  .content-wrap.about .about-grid .grid-item-2 {
    grid-column: 2/3;
    overflow: visible;
    padding-top: 3rem;
    padding-right: 2rem;
    margin-top: 0;
  }
  .content-wrap.about .about-grid .grid-item-2 .text-wrap p {
    max-width: 375px;
    padding-right: 1rem;
  }
  .content-wrap.about .about-grid .grid-item-3 {
    grid-column: 3/4;
    padding-top: 5rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap {
    width: 380px;
    padding-right: 1rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap {
    width: 100%;
    padding-left: 0.75rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap h5 {
    color: #4d8c9f;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.5em;
    margin: 0 auto 0.5em 0;
  }
  .content-wrap.about .resume-btn-wrap {
    position: absolute;
    left: 8rem;
    bottom: 0;
    width: 15rem;
    height: 15rem;
  }
  @keyframes explosion {
    0% {
      background-image: url("../assets/images/explosion-02.svg");
    }
    40% {
      background-image: url("../assets/images/explosion-01.svg");
    }
    100% {
      background-image: url("../assets/images/explosion-01.svg");
    }
  }
  .content-wrap.about .resume-btn-wrap a {
    text-decoration: none;
  }
  @keyframes move-arrow-left {
    0%, 100% {
      left: -70px;
    }
    50% {
      left: -85px;
    }
  }
  @keyframes move-arrow-right {
    0%, 100% {
      right: -70px;
    }
    50% {
      right: -85px;
    }
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left,
.content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
    width: 2.75em;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left {
    top: 4px;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
    top: 4px;
  }
}
@media only screen and (min-width: 1701px) {
  .content-wrap.about .about-grid {
    margin: auto auto auto 3rem;
  }
}
@media only screen and (min-width: 1801px) {
  .content-wrap.about .cat-img-wrap {
    top: 10%;
    width: 30vw;
  }
}
@media only screen and (min-width: 1901px) {
  .content-wrap.about {
    /* CAT ILLUSTRATION */
  }
  .content-wrap.about .cat-img-wrap {
    top: 230px;
    width: 25vw;
    height: 25vw;
  }
  .content-wrap.about .about-grid {
    margin: 2rem auto auto 7rem;
    column-gap: 5rem;
  }
  .content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
    width: 240px;
    height: 240px;
  }
  .content-wrap.about .resume-btn-wrap {
    width: 18rem;
    height: 18rem;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn span {
    font-size: 1.75rem;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left {
    top: 0.5em;
    width: 3.25em;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
    top: 0.5em;
    width: 3.25em;
  }
}
@media only screen and (min-width: 2001px) {
  .content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
    width: 240px;
    height: 240px;
    max-width: 100%;
  }
}
@media only screen and (min-width: 2301px) {
  .content-wrap.about .about-grid {
    margin: 4rem auto auto 10rem;
    column-gap: 5rem;
  }

  .content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
    width: 280px;
    height: 280px;
  }

  .content-wrap.about .resume-btn-wrap {
    width: 23rem;
    height: 27rem;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .resume-btn span {
    font-size: 2rem;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left {
    top: 0.3em;
    width: 3.5em;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
    top: 0.3em;
    width: 3.5em;
  }
}
@media only screen and (min-width: 2401px) {
  .content-wrap.about .about-grid {
    max-width: 1500px;
    grid-template-columns: 2fr 5fr 6fr;
    margin: 5rem auto auto 15rem;
  }
  .content-wrap.about .about-grid h2 {
    font-size: 2.75rem;
  }
  .content-wrap.about .about-grid .grid-item-1 .me-img-wrap {
    width: 300px;
    height: 300px;
  }
  .content-wrap.about .about-grid .grid-item-2 .text-wrap p {
    max-width: 100%;
    padding-right: 1rem;
    font-size: 1.125rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap {
    margin-bottom: 1.25rem;
    width: 100%;
    padding-right: 2rem;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .icon-wrap {
    width: 50px;
    height: 50px;
  }
  .content-wrap.about .about-grid .grid-item-3 .stats-list-wrap .stats-list .stats-wrap .stats-bar-wrap h5 {
    font-size: 1rem;
  }
  @keyframes move-arrow-left {
    0%, 100% {
      left: -80px;
    }
    50% {
      left: -95px;
    }
  }
  @keyframes move-arrow-right {
    0%, 100% {
      right: -80px;
    }
    50% {
      right: -95px;
    }
  }
  .content-wrap.about .resume-btn-wrap {
    left: 12rem;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-left {
    top: 0.1em;
    width: 4em;
  }
  .content-wrap.about .resume-btn-wrap a .btn-outer-border .arrow-right {
    top: 0.1em;
    width: 4em;
  }
}
/* --------------- Work --------------- */
.white-bg.work {
  background-color: rgba(255, 255, 255, 0.75);
}

.workpage-content {
  opacity: 1;
  transition: all 0.1s ease-out;
}
.workpage-content.fade {
  opacity: 0;
  transition: all 0.1s ease;
}
.workpage-content.hide {
  display: none;
}

.gradient-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0) 90%);
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
}
.gradient-overlay.show-overlay {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

.content-wrap.work {
  position: relative;
  top: 1rem;
  opacity: 0;
  transition: all 0.2s ease;
  display: block;
}
.content-wrap.work.reveal-work {
  top: 0rem !important;
  opacity: 1 !important;
  transition: all 0.2s ease;
}
.content-wrap.work.hide {
  display: none;
}
.content-wrap.work .back-arrow {
  opacity: 0;
  right: 0;
  transition: opacity 1s ease-out;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.content-wrap.work .container.work h1 {
  font-size: 2.5rem;
  margin-bottom: 1.75rem;
}
.content-wrap.work .container.work .filter {
  margin-bottom: 1rem;
  color: #bbb;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.content-wrap.work .container.work .filter .color-black {
  color: #1a1a1a;
  transition: all 0.3s ease;
}
.content-wrap.work .container.work .projects-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 6rem;
  row-gap: 6rem;
}

@media only screen and (min-width: 1024px) {
  .white-bg-wrap {
    position: relative;
  }
  .white-bg-wrap .white-bg.work {
    background-color: rgb(255, 255, 255);
    transition: all 0.3s ease;
  }
  .white-bg-wrap.resize-white-bg .white-bg.work {
    background-color: rgb(245, 245, 245);
    transition: all 0.3s ease;
  }

  .content-wrap.work {
    position: relative;
    top: 1rem;
    opacity: 0;
    transition: all 0.2s ease;
    display: block;
  }
  .content-wrap.work.reveal-work {
    top: 0rem !important;
    opacity: 1 !important;
  }
  .content-wrap.work.hide {
    display: none;
  }
  .content-wrap.work .back-arrow {
    opacity: 0;
    right: 0;
    transition: opacity 1s ease-out;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }
  .content-wrap.work .container.work h1 {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }
  .content-wrap.work .container.work .filter {
    margin-bottom: 1.5rem;
    color: #bbb;
    font-size: 1.75rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .content-wrap.work .container.work .filter .color-black {
    color: #1a1a1a;
    transition: all 0.3s ease;
  }
  .content-wrap.work .container.work .projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 3rem;
  }
}
@media only screen and (min-width: 1920px) {
  .content-wrap.work .container.work .filter {
    font-size: 2rem;
    margin-bottom: 1.75rem;
  }

  .content-wrap.work .container.work .projects-container {
    column-gap: 4rem;
    row-gap: 4rem;
  }
}
@media only screen and (min-width: 2400px) {
  .content-wrap.work .container.work .filter {
    font-size: 2.5rem;
    margin-bottom: 1.75rem;
  }

  .content-wrap.work .container.work h1 {
    font-size: 4rem;
    margin-bottom: 2.5rem;
  }

  .content-wrap.work .container.work .projects-container {
    column-gap: 6rem;
    row-gap: 6rem;
  }
}
/* --------------- Contact --------------- */
.content-wrap.contact .container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@keyframes shift-up {
  0% {
    top: 15px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes shift-down {
  0% {
    top: -15px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.content-wrap.contact .container h1 {
  line-height: 1em;
  font-size: 2.5rem;
  position: relative;
  opacity: 0;
  margin-bottom: 1.75rem;
  animation-name: shift-up;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
.content-wrap.contact .container p {
  position: relative;
  opacity: 0;
  margin-top: 1.125rem;
  max-width: 550px;
  animation-name: shift-down;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}
.content-wrap.contact .container .arrow-icons-container .col-1 {
  display: none;
}
.content-wrap.contact .container .arrow-icons-container .col-2 {
  width: 70%;
  max-width: 240px;
}
.content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1rem;
  animation-name: shift-left;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
  margin-left: 2rem;
}
.content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
  margin-left: 0;
}
@keyframes shift-left {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes spin-icons {
  0% {
    transform: rotate(-250deg);
  }
  100% {
    transform: rotate(-719deg);
  }
}
.content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap span {
  width: 30px;
  height: 30px;
  animation-name: spin-icons;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-timing-function: ease-out;
}
.content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap .coloured-icon.envelope {
  width: 43px;
}
.content-wrap.contact .monkey-container .monkey-wrap {
  max-width: 580px;
  margin: 3rem auto 0;
}

@media only screen and (min-width: 601px) {
  .content-wrap.contact .container h1 {
    font-size: 2.75rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    width: 70%;
    max-width: 240px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap span {
    width: 35px;
    height: 35px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap .coloured-icon.envelope {
    width: 51px;
  }
  .content-wrap.contact .monkey-container .monkey-wrap {
    margin: 4rem auto 0;
  }
}
@media only screen and (min-width: 1024px) {
  .content-wrap.contact {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 2rem;
    overflow: hidden;
  }
  .content-wrap.contact .container h1 {
    font-size: 3.5rem;
    margin-bottom: 0;
  }
  .content-wrap.contact .container p {
    margin-top: 0;
    animation-name: shift-down;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1.2s;
  }
  .content-wrap.contact .container .arrow-icons-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 {
    display: block;
    width: 60%;
  }
  @keyframes shrink-grow {
    0% {
      width: 100%;
    }
    35% {
      width: 95%;
    }
    70% {
      width: 100%;
    }
    100% {
      width: 100%;
    }
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer {
    animation-name: shrink-grow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
  }
  @keyframes shift-right {
    0% {
      left: -100%;
      opacity: 0;
    }
    100% {
      left: 0%;
      opacity: 1;
    }
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer .big-arrow-wrap {
    height: 2px;
    background-color: #1a1a1a;
    position: relative;
    margin: 1.5rem 0;
    position: relative;
    left: -120%;
    transition: all 1s ease-out;
    animation-name: shift-right;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer .big-arrow-wrap .pointer-head {
    width: 20px;
    height: 40px;
    background-color: #1a1a1a;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    position: absolute;
    right: -19px;
    top: -19px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    width: 40%;
    max-width: initial;
    padding-left: 4rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap {
    margin: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
    margin-left: 2.75rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
    margin-left: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap span {
    width: 40px;
    height: 40px;
    animation-name: spin-icons;
    animation-duration: 1.2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-timing-function: ease-out;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap .coloured-icon.envelope {
    width: 59px;
  }
  .content-wrap.contact .monkey-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin-left: 0rem;
    opacity: 1;
    transition: all 0.1s ease;
  }
  .content-wrap.contact .monkey-container.content-right {
    margin-left: 2rem;
    opacity: 0;
    transition: all 0.1s ease-out;
  }
  .content-wrap.contact .monkey-container .monkey-wrap {
    width: 65vh;
    max-width: 700px;
    margin: 0 11rem 0 auto;
  }
}
@media only screen and (min-width: 1440px) {
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer .big-arrow-wrap .pointer-head {
    width: 30px;
    height: 50px;
    background-color: #1a1a1a;
    -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    position: absolute;
    right: -24px;
    top: -24px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    padding-left: 4rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
    margin-left: 3rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
    margin-left: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon {
    width: 50px;
    height: 50px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon.envelope {
    width: 73px;
  }
}
@media only screen and (min-width: 1600px) {
  .content-wrap.contact .container .arrow-icons-container .col-1 {
    width: 55%;
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer .big-arrow-wrap .pointer-head {
    width: 40px;
    height: 80px;
    right: -39px;
    top: -39px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    width: 45%;
    padding-left: 6rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
    margin-left: 3rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
    margin-left: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon {
    width: 50px;
    height: 50px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon.envelope {
    width: 73px;
  }
}
@media only screen and (min-width: 1900px) {
  .content-wrap.contact {
    padding-bottom: 4rem;
  }
  .content-wrap.contact .container h1 {
    font-size: 4rem;
  }
  .content-wrap.contact .container p {
    font-size: 1.125rem;
  }
  @keyframes shift-left {
    0% {
      left: 150%;
    }
    100% {
      left: 0%;
    }
  }
  @keyframes shift-right {
    0% {
      left: -150%;
      opacity: 0;
    }
    100% {
      left: 0%;
      opacity: 1;
    }
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    padding-left: 7rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
    margin-left: 3.25rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
    margin-left: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon {
    width: 55px;
    height: 55px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon.envelope {
    width: 80px;
  }
  .content-wrap.contact .monkey-container {
    padding-right: 10rem;
    align-items: flex-end;
  }
  .content-wrap.contact .monkey-container .monkey-wrap {
    width: 40%;
    max-width: 950px;
    margin: 0;
  }
}
@media only screen and (min-width: 2400px) {
  .content-wrap.contact {
    padding: 12rem 14rem 6rem;
  }
  .content-wrap.contact .container h1 {
    font-size: 5rem;
  }
  .content-wrap.contact .container p {
    font-size: 1.25rem;
    max-width: 660px;
    line-height: 1.7;
  }
  @keyframes shift-right {
    0% {
      left: -150%;
      opacity: 0;
    }
    100% {
      left: 0%;
      opacity: 1;
    }
  }
  .content-wrap.contact .container .arrow-icons-container .col-1 .big-arrow-outer .big-arrow-wrap {
    left: -150%;
    animation-name: shift-right;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 {
    padding-left: 8.5rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li {
    margin-left: 3.75rem;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li:first-child {
    margin-left: 0;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon {
    width: 60px;
    height: 60px;
  }
  .content-wrap.contact .container .arrow-icons-container .col-2 .contact-icons-wrap li .coloured-icon.envelope {
    width: 86px;
  }
  .content-wrap.contact .monkey-container {
    padding-right: 10rem;
  }
  .content-wrap.contact .monkey-container .monkey-wrap {
    width: 70%;
    max-width: 1000px;
    margin: 0;
  }
}
.content-wrap.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}
.content-wrap.coming-soon h1 {
  font-size: 2rem;
}

@media only screen and (min-width: 800px) {
  .content-wrap.coming-soon h1 {
    font-size: 3rem;
  }
  .content-wrap.coming-soon p {
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1920px) {
  .content-wrap.coming-soon h1 {
    font-size: 5rem;
  }
  .content-wrap.coming-soon p {
    font-size: 1.125rem;
  }
}
.project-tile {
  padding-bottom: 2rem;
  box-shadow: 0 26px 14px -15px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.project-tile img {
  width: 100%;
}
.project-tile .thumb-wrap {
  position: relative;
  transition: opacity 0.3s ease;
  opacity: 1;
}
.project-tile .thumb-wrap .external-link-thumb::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: rgb(0, 50, 170);
  transition: all 0.3s ease;
}
.project-tile .thumb-wrap .external-link-thumb:hover {
  transition: opacity 0.1s ease;
  opacity: 0.8;
  cursor: pointer;
}
.project-tile .thumb-wrap .external-link-thumb:hover::after {
  background-color: rgb(0, 50, 170);
  opacity: 0.5;
  transition: all 0.1s ease;
}
.project-tile .details-wrap {
  padding: 0 2rem;
}
.project-tile .details-wrap .text-wrap h2 {
  margin-top: 1rem;
}
.project-tile .details-wrap .text-wrap .skills {
  text-transform: none;
  font-weight: 400;
  color: #777;
  font-size: 1rem;
  margin-bottom: 1em;
}
.project-tile .details-wrap .text-wrap p {
  margin-bottom: 1.5rem;
}
.project-tile .details-wrap .text-wrap .tag-list {
  text-transform: none;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.project-tile .details-wrap .text-wrap .tag-list li {
  margin-right: 0.5rem;
  border-right: solid 1px #777;
  padding-right: 0.5rem;
  position: relative;
  line-height: 1.2;
}
.project-tile .details-wrap .text-wrap .tag-list li:last-child {
  border-right: none;
}
.project-tile .details-wrap .text-wrap .tag-list li button {
  background: transparent;
  border: none;
  padding: 0;
  color: #777;
}
.project-tile .details-wrap .text-wrap .tag-list li button:hover {
  color: rgb(70, 170, 255);
  cursor: pointer;
}
.project-tile .details-wrap .text-wrap .tag-list li button.selected {
  color: rgb(70, 170, 255);
}
.project-tile .details-wrap .read-more-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.project-tile .details-wrap .read-more-wrap .read-more-btn, .project-tile .details-wrap .read-more-wrap a {
  margin-right: 2em;
  position: relative;
}
.project-tile .details-wrap .read-more-wrap .github-link, .project-tile .details-wrap .read-more-wrap .external-link {
  margin-right: 3.3rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
}
.project-tile .details-wrap .read-more-wrap .github-link .img-wrap, .project-tile .details-wrap .read-more-wrap .external-link .img-wrap {
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  display: inline-block;
  mask-size: cover !important;
  mask: url("../assets/icons/icon-github.svg");
  background-color: rgb(70, 170, 255);
  transition: color 0.3s ease;
  transition: right 0.4s ease-out, width 0.4s ease-out, height 0.4s ease-out;
  right: -1.7em;
}
.project-tile .details-wrap .read-more-wrap .github-link:hover .img-wrap, .project-tile .details-wrap .read-more-wrap .external-link:hover .img-wrap {
  background-color: rgb(110, 230, 255);
  transition: color 0.1s ease-out;
  transition: right 0.2s ease-out, width 0.2s ease-out, height 0.2s ease-out;
  right: -2em;
  width: 1.6em;
  height: 1.6em;
}
.project-tile .details-wrap .read-more-wrap .external-link .img-wrap {
  width: 1.2em;
  height: 1.2em;
  mask: url("../assets/icons/external-link-icon.svg");
}
.project-tile .details-wrap .read-more-wrap .play-me {
  background-color: rgb(70, 170, 255);
  color: #ffffff;
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  padding: 0.3rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  margin: auto;
  text-transform: uppercase;
  font-weight: 600;
  border: solid 2px rgb(52, 143, 223);
}
.project-tile .details-wrap .read-more-wrap .play-me:hover {
  background-color: #baf;
  border: solid 2px rgb(157, 141, 222);
}

@media only screen and (min-width: 1024px) {
  .project-tile {
    padding-bottom: 2rem;
    box-shadow: 0 26px 14px -15px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .project-tile img {
    width: 100%;
  }
  .project-tile .details-wrap {
    padding: 0 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .project-tile .details-wrap .text-wrap h2 {
    margin: 1rem auto 0;
    padding-top: 1rem;
  }
  .project-tile .details-wrap .text-wrap .tag-list {
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1em;
    display: flex;
    flex-wrap: wrap;
  }
  .project-tile .details-wrap .text-wrap .tag-list li {
    margin-right: 0.5rem;
    border-right: solid 1px #777;
    padding-right: 0.5rem;
    position: relative;
    line-height: 1.2;
  }
  .project-tile .details-wrap .text-wrap .tag-list li:last-child {
    border-right: none;
  }
  .project-tile .details-wrap .text-wrap .tag-list li button {
    background: transparent;
    border: none;
    padding: 0;
    color: #777;
  }
  .project-tile .details-wrap .text-wrap .tag-list li button:hover {
    color: rgb(70, 170, 255);
    cursor: pointer;
  }
  .project-tile .details-wrap .text-wrap .tag-list li button.selected {
    color: rgb(70, 170, 255);
  }
}
.project-popup-wrap {
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
  background-color: #ffffff;
}
.project-popup-wrap .white-bg.project-popup {
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease;
}
.project-popup-wrap .white-bg.project-popup.reveal {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s ease-out;
}
.project-popup-wrap .white-bg.project-popup .content-wrap .centraliser {
  max-width: 800px;
  margin: 0;
}
.project-popup-wrap .white-bg.project-popup .content-wrap .centraliser hr {
  margin: 0 auto 1rem;
}
.project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .project-popup-component {
  padding-bottom: 6rem;
}
.project-popup-wrap .white-bg.project-popup .cross {
  position: fixed;
  /* right: 0; */
  top: 0rem;
  right: 0rem;
  display: block;
  cursor: pointer;
  background: #ffffff;
  border: none;
  z-index: 4;
  width: 2.25rem;
  /* height: 2rem; */
  border-radius: 0 0 0 2px;
  /* padding-top: 0.25rem; */
  /* padding-right: 0rem; */
  padding: 0.5rem;
  opacity: 0;
}
.project-popup-wrap .white-bg.project-popup .cross.fade-in {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.2s;
}

@media only screen and (min-width: 601px) {
  .project-popup-wrap .white-bg.project-popup .cross {
    width: 3rem;
    padding: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .project-popup-wrap .white-bg.project-popup .content-wrap {
    padding: 8rem 0;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .centraliser {
    width: 100%;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .centraliser hr {
    margin: 0 auto 1.5rem;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .project-popup-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 8rem;
  }
  .project-popup-wrap .white-bg.project-popup .cross {
    top: 2rem;
    right: calc(3vh + 2rem);
  }
}
@media only screen and (min-width: 1200px) {
  .project-popup-wrap {
    width: 100%;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap {
    width: 80%;
    margin: auto;
  }
}
@media only screen and (min-width: 1440px) {
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap {
    width: 100%;
    max-width: 1080px;
    margin: auto;
  }
  .project-popup-wrap .white-bg.project-popup .cross {
    top: 1rem;
    right: calc(8vh + 1.5rem);
  }
}
@media only screen and (min-width: 1920px) {
  .project-popup-wrap .white-bg.project-popup .content-wrap {
    padding: 10rem 0;
    width: 80%;
    margin: 0 auto;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .centraliser {
    max-width: 800px;
    margin: 0;
  }
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .project-popup-component {
    padding-bottom: 10rem;
  }
  .project-popup-wrap .white-bg.project-popup .cross {
    top: 2rem;
    right: calc(8vh + 2rem);
  }
}
@media only screen and (min-width: 2400px) {
  .project-popup-wrap .white-bg.project-popup .content-wrap .another-wrap .centraliser hr {
    margin: 0 auto 2rem;
  }
}
.work-sample-section {
  margin: 3rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-sample-section figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-sample-section figcaption, .work-sample-section .caption {
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-top: 0.75em;
}
.work-sample-section a {
  position: relative;
  top: 1.5rem;
}
.work-sample-section .apple-window-wrap {
  width: 100%;
  z-index: 2;
  position: relative;
  border-radius: 7px 7px 0px 7px;
  border: solid 1px rgb(210, 210, 210);
  opacity: 1;
  box-shadow: 0px 14px 15px -10px rgba(0, 0, 0, 0.15);
}
.work-sample-section .apple-window-wrap .apple-navbar {
  border-radius: 7px 7px 0 0;
  padding: 10px 15px;
  background: linear-gradient(180deg, rgb(245, 245, 245), rgb(220, 220, 220));
  border-bottom: solid 1px rgb(210, 210, 210);
  display: flex;
}
.work-sample-section .apple-window-wrap .apple-navbar .circle {
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
}
.work-sample-section .apple-window-wrap .apple-navbar .circle:nth-child(1) {
  background-color: rgb(200, 50, 0);
}
.work-sample-section .apple-window-wrap .apple-navbar .circle:nth-child(2) {
  background-color: rgb(240, 225, 0);
}
.work-sample-section .apple-window-wrap .apple-navbar .circle:nth-child(3) {
  background-color: rgb(90, 200, 0);
}
.work-sample-section .apple-window-wrap .screen {
  height: 80vh;
  overflow-y: hidden;
  scrollbar-color: #bbb #ddd;
  scrollbar-width: thin;
  background-color: #eee;
  border-radius: 0 0 0px 7px;
}
.work-sample-section .apple-window-wrap .screen .scroll-overlay, .work-sample-section .apple-window-wrap .screen .scroll-me-overlay {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border: solid 1px rgba(0, 0, 0, 0.5);
  z-index: 2;
  border-radius: 7px 7px 0px 7px;
}
.work-sample-section .apple-window-wrap .screen .scroll-overlay > div, .work-sample-section .apple-window-wrap .screen .scroll-me-overlay > div {
  background: rgba(0, 0, 0, 0.7);
  padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-sample-section .apple-window-wrap .screen .scroll-overlay > div span, .work-sample-section .apple-window-wrap .screen .scroll-me-overlay > div span {
  font-size: 1.25rem;
  font-weight: 600;
}
.work-sample-section .apple-window-wrap .screen .scroll-overlay > div .arrow, .work-sample-section .apple-window-wrap .screen .scroll-me-overlay > div .arrow {
  width: 50px;
  transform: rotate(180deg);
  margin-top: 1rem;
  position: relative;
  top: 0px;
  animation-name: point-down;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
@keyframes point-down {
  0% {
    top: 0;
  }
  35% {
    top: 10px;
  }
  70% {
    top: 0;
  }
  100% {
    top: 0;
  }
}
.work-sample-section .apple-window-wrap .screen .scroll-me-overlay {
  display: none;
}
.work-sample-section .apple-window-wrap .screen.scroll-active {
  overflow-y: scroll;
}
.work-sample-section .apple-window-wrap .screen.scroll-active .scroll-overlay {
  display: none;
}
.work-sample-section .apple-window-wrap .screen picture img {
  border-radius: 0 0 0px 7px;
  width: 100%;
}
.work-sample-section .static-apple-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.work-sample-section .static-apple-window .apple-window-wrap {
  border-radius: 7px;
  margin: 0;
}
.work-sample-section .static-apple-window .apple-window-wrap .screen {
  height: auto;
  border-radius: 0 0 7px 7px;
}
.work-sample-section .static-apple-window .apple-window-wrap .screen .scroll-overlay {
  display: none;
}
.work-sample-section .static-apple-window .window-link-wrap {
  display: none;
}
.work-sample-section .video-tablet {
  width: 100%;
}
.work-sample-section .video-tablet .youtube-embed {
  width: 100%;
  height: 50vw;
}

@media only screen and (min-width: 1024px) {
  .work-sample-section {
    margin: 3.5rem auto;
  }
  .work-sample-section a {
    top: 2rem;
  }
  .work-sample-section .apple-window-wrap .screen {
    overflow-y: scroll;
  }
  .work-sample-section .apple-window-wrap .screen .scroll-overlay {
    display: none;
  }
  .work-sample-section .apple-window-wrap .screen .scroll-me-overlay {
    display: block;
    margin: auto auto 0 auto;
    height: 12rem;
    border-radius: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    opacity: 1;
  }
  .work-sample-section .apple-window-wrap .screen:hover .scroll-me-overlay {
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease;
  }
  .work-sample-section .static-apple-window .apple-window-wrap .screen .scroll-me-overlay {
    display: none;
  }
  .work-sample-section .video-tablet {
    padding: 1.5rem;
    border: solid 2px rgb(210, 210, 210);
    border-radius: 17px;
    box-shadow: 0 12px 10px -6px rgba(80, 80, 83, 0.1);
    background: rgb(245, 245, 245);
    width: 100%;
  }
  .work-sample-section .video-tablet .youtube-embed {
    height: 420px;
  }
  .work-sample-section .static-apple-window {
    position: relative;
  }
  .work-sample-section .static-apple-window .window-link-wrap {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 1.25rem;
    opacity: 1;
    border-radius: 7px;
    opacity: 0;
  }
  .work-sample-section .static-apple-window .window-link-wrap:hover {
    opacity: 1;
  }
}
@media only screen and (min-width: 1440px) {
  .work-sample-section {
    margin: 4rem auto;
  }
  .work-sample-section figcaption, .work-sample-section .caption {
    margin-top: 1em;
  }
  .work-sample-section .apple-window-wrap .screen {
    height: 675px;
  }
  .work-sample-section .video-tablet .youtube-embed {
    height: 580px;
  }
}
@media only screen and (min-width: 1920px) {
  .work-sample-section {
    margin: 6rem auto;
  }
}
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease 0.6s;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.handlepage-test p, .handlepage-test2 p {
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  background-color: #3d3d3d;
  color: #fff;
  font-size: 2rem;
  z-index: 2;
}

.handlepage-test2 p {
  left: 200px;
}
